<template>
  <v-form>
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
      :loading="loading"
      :disabled="loading || blockEditing"
    >
      <v-card-title v-text="$t('SeminarPackage')" />
      <v-card-text>
        <h3 v-if="item.number">#{{ item.number }}</h3>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                :error-messages="nameErrors"
                :label="$t('name')"
                @blur="$v.item.name.$touch()"
                @input="$v.item.name.$touch()"
                required
                v-model="item.name"
              />
            </v-col>
            <v-col>
              <h3>{{ $t('description') }}</h3>
              <br />
              <InputEditor
                v-model="item.description"
                :error-messages="descriptionErrors"
              />
            </v-col>
          </v-row>
          <template v-if="item['@id']">
            <v-row>
              <v-col cols="12">
                <h3>{{ $t('Seminars') }}</h3>
                <br />
                <v-combobox
                  v-model="itemSeminars"
                  :error-messages="seminarsErrors"
                  :items="seminars"
                  :no-data-text="$t('No results')"
                  chips
                  item-text="name"
                  item-value="@id"
                  multiple
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>{{ $t('Addons') }}</h3>
                <br />
                <treeselect
                  v-model="item.addons"
                  :multiple="true"
                  :options="groupedAddons"
                  :normalizer="normalizer"
                  :flat="true"
                  placeholder="Addons"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DiscountListAndAdd
                  :default-item-props="{ seminarPackage: this.item['@id'] }"
                  :show-message="showMessage"
                  :discounts="this.item.discounts"
                  object-type="offer"
                  @updateDiscounts="updateDiscountsHandler"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col>
                <v-alert
                  text
                  type="info"
                > {{ $t('saveSeminarPackageFirst') }}</v-alert>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
  import has from 'lodash/has';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import InputEditor from '@/components/InputEditor';
  import DiscountListAndAdd from '@/components/discount/ListAndAdd';
  import SeminarMixin from '@/mixins/SeminarMixin';
  import AddonMixin from '@/mixins/AddonMixin';
  import { mapFields } from 'vuex-map-fields';
  import { mapActions } from 'vuex';
  import NotificationMixin from '../../mixins/NotificationMixin';
  import Treeselect from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';

  export default {
    name: 'SeminarPackageForm',
    mixins: [AddonMixin, validationMixin, SeminarMixin, NotificationMixin],
    components: {
      InputEditor,
      DiscountListAndAdd,
      Treeselect,
    },
    props: {
      values: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        default: () => { }
      },
      initialValues: {
        type: Object,
        default: () => { }
      },
      loading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        name: null,
        enabled: false
      };
    },
    methods: {
      ...mapActions({
        seminarGetSelectItems: 'seminar/fetchSelectItems',
        addonGetSelectItems: 'addon/fetchSelectItems'
      }),
      updateDiscountsHandler(updatedDiscounts) {
        this.item.discounts = updatedDiscounts;
      },
      normalizer(node) {
        return {
          label: node['title'],
          children: node.children
        }
      }
    },
    computed: {
      item() {
        return this.initialValues || this.values;
      },
      blockEditing() {
        return this.item && this.item.participants && this.item.participants.length > 0 || false;
      },
      //region validation:
      nameErrors() {
        const errors = [];

        if (!this.$v.item.name.$dirty) {
          return errors;
        }

        has(this.violations, 'name') && errors.push(this.violations.name);

        !this.$v.item.name.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      descriptionErrors() {
        const errors = [];

        if (!this.$v.item.description.$dirty) {
          return errors;
        }

        has(this.violations, 'description') && errors.push(this.violations.description);
        return errors;
      },
      seminars() {
        if (this.seminarsSelectItems === null) {
          return [];
        }
        return this.seminarsSelectItems;
      },
      addons() {
        if (this.addonsSelectItems === null) {
          return [];
        }
        return this.addonsSelectItems;
      },
      itemSeminars: {
        get: function () {
          return this.resolveSeminars(this.item.seminars);
        },
        set: function (newValue) {
          this.item.seminars = [];
          newValue.forEach((item) => {
            this.item.seminars.push(item['@id']);
          });
        }
      },
      itemAddons: {
        get: function () {
          return this.resolveAddons(this.item.addons);
        },
        set: function (newValue) {
          this.item.addons = [];
          newValue.forEach((item) => {
            this.item.addons.push(item['@id']);
          });
        }
      },
      ...mapFields('seminar', {
        seminarsSelectItems: 'selectItems',
        seminarsById: 'byId'
      }),
      ...mapFields('addon', {
        addonsSelectItems: 'selectItems',
        addonsById: 'byId'
      }),
      seminarsErrors() {
        const errors = [];

        if (!this.$v.item.seminars.$dirty) {
          return errors;
        }

        has(this.violations, 'seminars') && errors.push(this.violations.seminars);

        return errors;
      }
    },
    validations: {
      item: {
        name: {
          required
        },
        description: {
          required
        },
        seminars: {},
        addons: {}
      }
    },
    mounted() {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      this.seminarGetSelectItems({
        params: {
          'seminarConfig.enabled': 1,
          'seminarConfig.confirmed': 0,
          'seminarConfig.cancelled': 0,
          'itemsPerPage': 100,
          'order[timeSlot.start]': 'DESC',
          'timeSlot.start[after]': yesterday.toISOString()
        }
      });
      this.addonGetSelectItems();
    }
  };
</script>
